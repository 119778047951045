.SignUp {
    text-align: center;
  }

.SignUp-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
.SignUp-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
.SignUp-link {
    color: #61dafb;
  }

.SignUp form a {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}