body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App {
  height: 100%;
}

:root {
  color-scheme: light !important;
  background-color: rgba(240, 242, 245, 0.5);
  /*background-image: url(common/Fotolia_142802670_XXL.jpg);*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*height: auto;*/
  /*width: 100%;*/
}