.Menu-text {
    color: #575756;
  }

.Articles {
  margin-left: 82px;
  margin-right: 12px;
  margin-top: 82px;
  padding: 6px;
}

.Header {
  text-align: start;
}