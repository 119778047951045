.App {
  text-align: center;
  height: 100%
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../../common/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('RobotoLight'), url(../../common/fonts/Roboto-Light.ttf) format('truetype');
}