@import './sidebar.scss';

.Menu {
    text-align: center;
    height: 100%;
    margin-top: 100px;
  }

.Content {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.Button {
  color: black;
}

.Menu-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
.Sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #fff;
}

.Margin {
  height: 8vh;
}

.ToBottom {
  position: fixed;
  bottom: 0;
  margin-bottom: 8px;
}

.Menu-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
.Menu-link {
    color: #61dafb;
  }

.Menu-text {
  color: #575756;
}

.AppBar {
  background-color: #fff;
}

.margin-container {
  height: 100%;
  margin-left: 210px;
  transition: margin-left 0.3s;
}

.no-margin {
  height: 100%;
  margin-left: 0;
  transition: margin-left 0.3s;
}

.expanded-arrow {
  margin-left: -7.5px;
  transition: margin-left 0.3s;
}

.collapsed-arrow {
  margin-left: -7.5px;
  transition: margin-left 0.3s;
}

.arrow {
  font-size: 28px;
}

.arrow-rotated {
  font-size: 28px;
  transform: rotate(180deg);
}

.link {
  &:active {
    font-weight: bold;
  }
}