.Menu-text {
    color: #575756;
  }

.Measures {
  margin-left: 82px;
  margin-right: 12px;
  margin-top: 82px;
  padding: 6px;
}

.Header {
  text-align: start;
}

.CSVImporter_FormatDataRowPreview__table>thead>tr>th, .CSVImporter_FormatDataRowPreview__table>tbody>tr>td {
  text-align: center;
}

.CSVImporter_ColumnDragCard {
  text-align: center;
}